.young-info-datepicker {
  justify-content: center;
  font-family: 'Manrope';
  align-content: center;
  border-radius: 3px;
  padding-left: 10px;
  border: 1px solid;
  margin-left: 5px;
  outline: none;
  display: flex;
  font-size: 14;
  width: 100px;
  margin: 0;
  margin-left: 5px;
}
